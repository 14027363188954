<template>
  <div class="border-card-player px-6 py-4 flex flex-col">
    <div class="mb-8">
      <h1 class="text-2xl font-bold tracking-tighter uppercase">
        {{ formPlayer.name }}
      </h1>
    </div>
    <main>
      <form @submit.prevent="handleSubmitInfoPlayer">
        <div class="form__div">
          <input
            class="form__input"
            type="date"
            id="date_birth"
            placeholder=" "
            v-model="formPlayer.date"
          />
          <label for="first_name" class="form__label">Date of Birth</label>
        </div>
        <div class="w-full form__div">
          <input
            class="form__input"
            type="text"
            id="first_name"
            placeholder=" "
            v-model="formPlayer.name"
          />
          <label for="first_name" class="form__label">Username</label>
        </div>
        <div class="form__div">
          <input
            :id="`phone_number_${player.id}`"
            class="form__input"
            type="text"
            placeholder=" "
            v-model="formPlayer.phoneNumber"
          />
          <label :for="`phone_number_${player.id}`" class="form__label"
            >Phone number (optional)</label
          >
        </div>
        <div class="bg-white py-2 flex justify-between items-center">
          <div class="w-full">
            <h3 class="text-lg font-bold text-color mb-1">Notifications</h3>
            <div class="flex justify-between items-center">
              <span class="text-gray-400 flex-grow">
                Opt In for Mastermind text messaging for important training
                updates
              </span>
              <input
                class="ml-4 cursor-pointer"
                type="checkbox"
                v-model="formPlayer.acceptedOptIn"
              />
            </div>
          </div>
        </div>
        <div class="m-auto border-b"></div>
        <h3 class="pt-2 text-lg font-bold text-color mb-1">Subscription</h3>
        <div v-if="isLoading" class="flex justify-center items-center pb-4">
          <em class="fa fa-spinner fa-spin"></em>
        </div>
        <div
          v-else-if="isSubscriptionSuspended && isSubscriptionExpired"
          class="bg-white pb-2 flex justify-between items-end"
        >
          <div class="flex items-center space-x-3">
            <img
              src="@/assets/icons/Info.svg"
              alt="Info"
              class="h-6 w-6 cursor-pointer"
              @click="showExpiredSubscriptionInfoModal = true"
            />
            <p class="text-gray-400">
              This subscription is currently suspended
            </p>
          </div>
        </div>
        <div
          v-else-if="formPlayer.subscriptionExpiryDate"
          class="bg-white pb-2 flex justify-between items-end"
        >
          <div class="flex items-center space-x-3">
            <img
              src="@/assets/icons/Info.svg"
              alt="Info"
              class="h-6 w-6 cursor-pointer"
              @click="showNoExpiredSubscriptionInfoModal = true"
            />
            <p class="text-gray-400">This player has been suspended</p>
          </div>
          <button
            v-if="!isSubscriptionSuspended"
            type="button"
            class="text-blue-500 underline hover:text-blue-700 focus:outline-none"
            @click="
              formPlayer.nexActivationDate && !formPlayer.isReactivatable
                ? (showActivationBlockedModal = true)
                : (showConfirmPlayerPaymentModal = true)
            "
          >
            Activate player
          </button>
        </div>
        <div
          v-else-if="!noSubscriptionFound && !isSubscriptionSuspended"
          class="bg-white pb-2 flex justify-between items-end"
        >
          <div>
            <p class="text-gray-400">Manage your Subscription</p>
          </div>
          <button
            type="button"
            class="text-blue-500 underline hover:text-blue-700 focus:outline-none"
            @click="showCancelModal = true"
          >
            Suspend player
          </button>
        </div>
        <div v-else class="bg-gray-100 p-4 mb-3 rounded-lg">
          <div class="flex items-center space-x-3">
            <img src="@/assets/icons/Info.svg" alt="Info" class="h-6 w-6" />
            <p class="text-gray-400 text-sm">No active subscription found</p>
          </div>
        </div>
        <div class="m-auto border-b"></div>

        <div class="flex items-center justify-end my-3 mt-6">
          <button
            class="text-color submit-btn font-bold px-4 py-2 w-full"
            type="submit"
          >
            Save changes
          </button>
        </div>
      </form>
    </main>
  </div>
  <CancelPlayerSubscriptionModal
    :show="showCancelModal"
    :playerName="formPlayer.name"
    @update:show="showCancelModal = $event"
    @confirm="handleCancelPartialSubscription"
    :isLoading="isCancellingPartialSubscription"
    :platform="platform"
    :expiryDate="expiryDate"
  />
  <ConfirmPlayerPaymentModal
    :show="showConfirmPlayerPaymentModal"
    @update:show="showConfirmPlayerPaymentModal = $event"
    @confirm="handleActivatePartialSubscription"
    :isLoading="isLoadingConfirmPayment"
    :platform="platform"
    :playerName="formPlayer.name"
  />
  <InformationModal
    :show="showNoExpiredSubscriptionInfoModal"
    @update:show="showNoExpiredSubscriptionInfoModal = $event"
    @confirm="showNoExpiredSubscriptionInfoModal = false"
    :title="'User subscription'"
    :content="getNoExpiredSubscriptionInfoContent()"
  />
  <InformationModal
    :show="showExpiredSubscriptionInfoModal"
    @update:show="showExpiredSubscriptionInfoModal = $event"
    @confirm="showExpiredSubscriptionInfoModal = false"
    :title="'User subscription'"
    :content="getExpiredSubscriptionInfoContent()"
  />
  <InformationModal
    :show="showCongratulationModal"
    @update:show="showCongratulationModal = $event"
    @confirm="showCongratulationModal = false"
    :title="'Activate subscription'"
    :subtitle="'Congratulations!'"
    :content="getCongratulationModalContent()"
  />
  <InformationModal
    :show="showNotSeatsModal"
    @update:show="showNotSeatsModal = $event"
    @confirm="showNotSeatsModal = false"
    :title="'Activate player'"
    :content="getNotAvailableSeatsContent()"
  />
  <InformationModal
    :show="showActivationBlockedModal"
    @update:show="showActivationBlockedModal = $event"
    @confirm="showActivationBlockedModal = false"
    :title="'Activate player'"
    :content="getActivationBlockedContent()"
  />
  <PaymentInformationModal
    :show="showPaymentModal"
    @update:show="showPaymentModal = $event"
    @confirm="handlePaymentMethod"
    :isLoading="isLoadingPayment"
    confirmationText="Activate player"
  />
</template>

<script setup>
import { ref, defineProps, watch, defineEmits } from "vue";
import { useToast } from "vue-toastification";
import { editPlayer } from "@/services/user/user";
import { formatPhoneNumber } from "@/utils/formatPhoneNumber.js";
import { calculateAge } from "@/utils/calculateAge.js";
import {
  cancelPartialSubscription,
  reactivePartialSubscription,
  attachPaymentMethod,
} from "@/services/stripe/stripe.js";
import {
  appStoreDeactivateUsers,
  appStoreReactivateUsers,
} from "@/services/appStore/appStore.js";
import InformationModal from "@/components/profile/modals/InformationModal.vue";
import CancelPlayerSubscriptionModal from "@/components/profile/modals/CancelPlayerSubscriptionModal.vue";
import ConfirmPlayerPaymentModal from "@/components/profile/modals/ConfirmPlayerPaymentModal.vue";
import PaymentInformationModal from "@/components/profile/modals/PaymentInformationModal.vue";
import stripe from "@/plugins/stripe";

const toast = useToast();

const props = defineProps({
  player: Object,
  isSubscriptionExpired: Boolean,
  isSubscriptionSuspended: Boolean,
  isLoading: Boolean,
  platform: String,
  expiryDate: String,
  availableSeats: Number,
  noSubscriptionFound: Boolean,
});

const formPlayer = ref({
  id: props.player.id,
  date: props.player.date_birth,
  name: props.player.username,
  phoneNumber: props.player?.phone_number?.replace(/^\+1/, ""),
  acceptedOptIn: props.player.opt_in_status,
  subscriptionExpiryDate: props.player.play_until_date,
  nexActivationDate: props.player.next_activation_date,
  isReactivatable: props.player.is_reactivatable,
});
const showCancelModal = ref(false);
const showNoExpiredSubscriptionInfoModal = ref(false);
const showExpiredSubscriptionInfoModal = ref(false);
const showConfirmPlayerPaymentModal = ref(false);
const isCancellingPartialSubscription = ref(false);
const isLoadingConfirmPayment = ref(false);
const showCongratulationModal = ref(false);
const platform = ref(props.platform);
const showNotSeatsModal = ref(false);
const showActivationBlockedModal = ref(false);
const showPaymentModal = ref(false);
const isLoadingPayment = ref(false);

const emit = defineEmits(["updateAvailableSeats", "getSubscriptionInfo"]);

async function handleSubmitInfoPlayer() {
  try {
    const body = {
      username: formPlayer.value.name.trim(),
      date_birth: formPlayer.value.date,
      profile: {
        phone_number: formPlayer.value.phoneNumber
          ? `+1${formPlayer.value.phoneNumber}`
          : "",
        opt_in_status: formPlayer.value.acceptedOptIn,
      },
    };
    await editPlayer(formPlayer.value.id, body);
    handleMessage(`${formPlayer.value.name} has been updated!`, false);
  } catch (error) {
    handleMessage(error.message, true);
  }
}

function handleMessage(message, error) {
  error ? toast.error(message) : toast.success(message);
}

async function handleActivatePartialSubscription() {
  try {
    if (platform.value === "Stripe") {
      isLoadingConfirmPayment.value = true;
      await reactivePartialSubscription(formPlayer.value.id);
    } else if (platform.value === "Apple") {
      await appStoreReactivateUsers([formPlayer.value.id]);
      emit("updateAvailableSeats");
    }

    formPlayer.value.subscriptionExpiryDate = "";
    showCongratulationModal.value = true;
  } catch (error) {
    if (platform.value === "Apple") {
      if (error.message === "There are no profiles available.") {
        showNotSeatsModal.value = true;
        return;
      }
    } else if (platform.value === "Stripe") {
      if (error.code === 400) {
        showPaymentModal.value = true;
      }
      handleMessage(error.message, true);
    }
  } finally {
    showConfirmPlayerPaymentModal.value = false;
    isLoadingConfirmPayment.value = false;
  }
}

async function handleCancelPartialSubscription(subscriptionExpiryDate) {
  isCancellingPartialSubscription.value = true;
  try {
    if (platform.value === "Stripe") {
      await cancelPartialSubscription(formPlayer.value.id);
    } else if (platform.value === "Apple") {
      await appStoreDeactivateUsers([formPlayer.value.id], false);
      emit("updateAvailableSeats");
    }

    formPlayer.value.subscriptionExpiryDate = subscriptionExpiryDate;
    showCancelModal.value = false;
    handleMessage(`${formPlayer.value.name} was successfully suspended`, false);
    emit("getSubscriptionInfo");
  } catch (error) {
    handleMessage(error.message, true);
  } finally {
    isCancellingPartialSubscription.value = false;
  }
}

async function handlePaymentMethod({ card, name }) {
  isLoadingPayment.value = true;
  const response = await stripe.createPaymentMethod({
    type: "card",
    card: card,
    billing_details: {
      name: name,
    },
  });

  try {
    await attachPaymentMethod(response.paymentMethod.id);
    await handleActivatePartialSubscription();

    showPaymentModal.value = false;
    showConfirmPlayerPaymentModal.value = true;
  } catch (error) {
    handleMessage(error.message, true);
  } finally {
    isLoadingPayment.value = false;
  }
}

function getNoExpiredSubscriptionInfoContent() {
  if (platform.value === "Stripe") {
    return `${formPlayer.value.name} will have access to the 
    Mastermind VR experience until date ${formPlayer.value.subscriptionExpiryDate}. 
    After this date, you can reactivate the subscription from the Personal Information tab.`;
  } else {
    return `${formPlayer.value.name} will have access to the 
    Mastermind VR experience until date ${formPlayer.value.subscriptionExpiryDate}.`;
  }
}

function getExpiredSubscriptionInfoContent() {
  if (platform.value === "Stripe") {
    return `Your subscription is currently suspended. Please reactivate it from the Personal Information tab.`;
  } else if (platform.value === "Apple") {
    return `Your subscription is currently suspended. Please reactivate it from your Apple ID settings.`;
  }
}

function getCongratulationModalContent() {
  return `${formPlayer.value.name} has been successfully activated, now have full access to all the Mastermind Cognitive Training experience.`;
}

function getNotAvailableSeatsContent() {
  return `Currently, you do not have any available seats to add a new player. 
  You can purchase additional seats from your Apple ID settings.`;
}

function getActivationBlockedContent() {
  return `${formPlayer.value.name} cannot be reactivated until ${formPlayer.value.nexActivationDate}. Please try again after this date`;
}

watch(
  () => formPlayer.value.date,
  (newValue, oldValue) => {
    if (newValue) {
      const age = calculateAge(newValue);
      if (age > 13) {
        formPlayer.value.acceptedOptIn = true;
      } else {
        formPlayer.value.acceptedOptIn = false;
      }
    }
  }
);

watch(
  () => formPlayer.value.phoneNumber,
  (newValue, oldValue) => {
    const formatted = formatPhoneNumber(newValue);
    if (newValue !== formatted) {
      formPlayer.value.phoneNumber = formatted;
    }
  }
);
</script>

<style scoped>
.border-card-player {
  border: 2px solid #ecf0f5;
  border-top: 13px solid #edfe86;
}
</style>
